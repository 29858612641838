table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    @include core-19;
    padding: em(12, 19) em(20, 19) em(9, 19) 0;

    text-align: left;
    border-bottom: 1px solid $border-colour;
  }

  thead th {
    font-family: 'arial_bold';
    font-weight: 700;
  }
 
  // style for numeric content in cells
  .numeric {
    text-align: right;
  }

  td.numeric {
    font-family: $helvetica-regular;
  }

  caption {
    text-align: left;
  }
}

.table-font-small {

  th {
    @include bold-19;
  }

  td {
    @include core-19;
  }

  th,
  td {
    padding: em(12, 19) em(20, 19) em(9, 19) 0;
  }
  
} 
