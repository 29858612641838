@use 'sass:math';

.header {
  background: $white;
  position: relative;
  
 

  .header-container {
    position: relative;
    padding-top: 5px;
  }
  .menu{
    //top: 9px !important;
  }

  .header-logo {
    float: left;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
    @include media(iphone){
      width: calc(31% - 15px);
    }
    @include media(mobile) {
      width: calc(60% - 15px);
    }
  }

  .header-link-home {
    margin: 1px 5px 0px 0px;
    color: $black;
    margin: 0 $gutter-one-third 0 0;
    float: left;
    margin-bottom: 5px;

    &:hover,
    &:visited {
      color: $black;
      span{
        text-decoration: underline;
      }
    }

    .header-logotype {
      float: left;
      margin: 2px 5px 2px 0;
      img {
        width: 100%;
        height: 55px;
        //margin-top: 15px;
        @include media(mobile){
          height: 40px;
       }
      }
    }
    &:focus{
      span{
        color: #0b0c0c!important;
      }
      &:hover{
        text-decoration: none!important;
      }
    }
  }

  .header-logotype-text {
    color: #000;
    @include bold-19();
    text-transform: uppercase;
    margin: math.div($gutter , 4) 0;
    float: left;
    font-size: 30px!important;
    font-family: 'helvetica_bold';
  }

  .header-navigation-container {
    padding: 13px 0 0 0;
    @include media(mobile){
      width: initial;
      padding: 8px 0 0 0;
   }
   li.header-list{
    // margin-right: 10px;
  }
 }
 ul.column-half {
    list-style: none;
    height: 32px;
    width: 52%;
    @include media(mobile) {
      height: auto;
      
    }
   
    .column-one-half{
      margin-right: 0;
      margin-top: 10px;
      .formstyle {
        .search-input {
          height: 35px;
          font-size: 16px;
          padding: 0 3px 0 5px;
        }
        
        input[type="search"]::-webkit-search-cancel-button {
          -webkit-appearance: searchfield;
        }
      }
      
    }
  }
  li {
    display: inline-block;
    height: 29px;
    border-bottom: 4px solid transparent;
    margin-left: 0;
    cursor: pointer;
    @include media(mobile) {
      display: block;
      width: auto;
      margin-left: 0;
    }
  }
  
  
  .navigation-item {
    @include core-24();
    display: inline-block;
    width: calc(100% - 40px);
    padding: ($gutter / 4) ($gutter / 3) ($gutter / 4) $gutter;
    // padding: 0 10px!important;
    // margin: $gutter / 5 0;
    text-decoration: none;
    text-align: center;
    box-shadow: 0 -2px transparent, 0 4px transparent;

    &:hover {
      outline: 3px solid transparent;
      text-decoration: underline;
    }

    &:active,
    &.active {
      background: $yellow;
      outline: none;
      box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      background: $yellow;
      box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
    }

    @include media(tablet) {
      @include core-16();
      width: auto;
      text-decoration: none;
      color: $black;
      padding: 0;
      margin: 0;
      // padding: 0 ($gutter / 2);
      padding: 0 0px;
      //margin: 0 $gutter / 15;
      float: left;
      text-align: center;
      cursor: pointer;
      font-family: 'arial_bold';
      font-weight: 700;
    }
    @include media(mobile) {
      float: left;
    }
  }

  li.active {
    border-bottom: 4px solid #002d18;
    @include media(mobile) {
      border-bottom: 0;
    }
    .navigation-item:hover, .navigation-item:focus, .navigation-item:active, .navigation-item.active {
      text-decoration: none;
    }
  }

  .header-navigation-container{
    .navigation-item {
      color: $main-blue;
      @include media(tablet) {
        color: $main-blue;
      }
      &:focus, &:active, &.active {
        color: $black;
      } 
    }
    
  
    li.active {
      border-bottom: 4px solid $main-blue;
    }
    
  }
  .header-navigation-container-background{
    background-color: #f3f2f1;
    border-top: 7px solid #73428e;
    margin-right: 15PX;
  }

  .navigation-item-hide {
    display: none;

    @include media(tablet) {
      &.navigation-item-show {
        display: inline-block;
      } 
    }
  }

  .navigation-item-mobile {
    display: inline-block;

    @include media(tablet) {
      display: none;
    }
  }

  .header-user-name,
  .header-button-user {
    display: none;

    @include media(tablet) {
      display: inline-block;
      margin-top: -5px;
    }
  }

    .header-button-menu {
      //display: none;
      //z-index: 9;
    }

  .header-navigation-right {
    float: right;
    text-align: right;
    @include media(tablet){
      width: calc(26% - 15px);
    }
    @include media(iphone){
      width: calc(31% - 15px);
    }
    
  }

  .header-button-close {
    display: none;
  }

  .navigation-overlay {
    @include media(tablet){
      width: calc(49% - 15px);
    }
    @include media(iphone){
      width: calc(37% - 15px);
    }
    @include media(mobile) {
      // display: none;
      margin-top: 0;
      position: fixed;
      top: 0;
      right: 0;
      height: 0;
      width: 100%;
      background: $grey-4;
      overflow: auto;
      z-index: 99;
      transition: 0.5s;

      .navigation-item {
        display: none;
      }

      &.navigation-overlay-show {
        // display: block;
        height: 100%;

        .navigation-item {
          display: block;
        }

        .header-navigation-container {
          padding: $gutter * 2 $gutter $gutter $gutter;
        }

        .navigation-item {
          padding: ($gutter / 3) 0;
          margin: 0;
          width: 100%;
          color: $black;
          text-align: left;
        }

        .header-button-close {
          display: block;
          position: absolute;
          top: $gutter / 2;
          right: $gutter / 4;
        }
      }
    }
  }


  .column-half, .column-quarter {
    margin-bottom: 0;
  }

  .column-half.navigation-overlay{
    width: calc(46% - 15px);
  }
  .column-quarter.header-navigation-right {
    width: calc(27% - 15px);
  }
  ul.rightNav {
    
    float: right;
    margin-right: 0;
    margin-top: -6px;
    .navigation-item:focus {
      border-bottom: 4px solid transparent;
    }
    .search{
      margin-bottom: 3px;
    float: right;
    width: 70%;
        margin-top: 0px;
    }
  }
}
li.smDiv{
  position: absolute;
  top:19px;
  font-family: "arial_bold";
  padding: 0 10px;
  font-weight: 700;
cursor: pointer;
.chat-container{
  .btn{
    background: none;
    border: none;
    padding: 0px 0px;
    &:hover{
      .chat-text{
        text-decoration: underline;
      }
    }
    .active{
         .chat-text{
          color: #27285C !important;
        }
       }
    &:focus{
      text-decoration: none;
      outline: none;
      background: #ffdd00;
      box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
      .chat-text{
        color: #0b0c0c;
        text-decoration: none;
      }
    }
   }
  //   .btn.active{
  //    .chat-text{
  //     color: #27285C !important;
  //   }
  //  }
  }
  // &:focus{
  //  .btn{
  //    text-decoration: none;
  //    outline: none;
  //    background: #ffdd00;
  //    box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
  //    .chat-text{
  //      color: #0b0c0c;
  //    }
  //  }
  // }
  &:hover{
    .chat-text{
      text-decoration: underline;
    }
  }
}
.chatBadge {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'arial_bold';
  color: #ffffff;
  display: inline-block;
  min-width: 15px;
  padding: 2px 5px 0px 4px;
  border-radius: 75px;
  background-color: #d4351c;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  right: -19px;
  top: -11px;
  width: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 700;
}
.header-navigation-box{
  position: relative;
}
.header .header-container ul.column-half .link2{
  margin-right: 40px;
  margin-left: 33px;
}
.header .header-container ul.column-half .link1{
margin-right: 0px;
}
.header .menu ul.header-list{
  padding-right: 15px;
}
.addtional-height{
  height: auto;
  min-height: 70px;
}
.white-space-preline{
  font-size: 19px;
}